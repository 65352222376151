import React, { createContext, useContext, useCallback } from 'react';

import { useCriptografia } from './CriptografiaContext';

interface StorageContextData {
  gravar(chave: string, valor: any): void;
  ler(chave: string): string | null;
  excluir(chave: string): void;
}

const StorageContext = createContext<StorageContextData>(
  {} as StorageContextData,
);

const nomeApp: string = '@ClinicaSizex';

const StorageProvider: React.FC = ({ children }) => {
  const cripto = useCriptografia();

  const gravar = useCallback(
    (chave: string, valor: any): void => {
      let valorFinal = '';

      if (typeof valor === 'string') {
        valorFinal = valor;
      } else if (typeof valor === 'object') {
        valorFinal = JSON.stringify(valor);
      } else if (typeof valor !== 'string') {
        valorFinal = String(valor);
      }

      valorFinal = cripto.encriptar(valorFinal);
      sessionStorage.setItem(`${nomeApp}:${chave}`, valorFinal);
    },
    [cripto],
  );

  const ler = useCallback(
    (chave: string): string | null => {
      let valor = sessionStorage.getItem(`${nomeApp}:${chave}`);
      if (!valor) {
        return '';
      }
      valor = cripto.decriptar(valor);
      return valor;
    },
    [cripto],
  );

  const excluir = useCallback((chave: string): void => {
    sessionStorage.removeItem(`${nomeApp}:${chave}`);
  }, []);

  return (
    <StorageContext.Provider value={{ gravar, ler, excluir }}>
      {children}
    </StorageContext.Provider>
  );
};

function useStorage(): StorageContextData {
  const context = useContext(StorageContext);

  if (!context) {
    throw new Error('useStorage deve ser usado dentro de um StorageProvider');
  }

  return context;
}

export { StorageProvider, useStorage };
