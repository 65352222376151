import { AxiosError } from 'axios';

interface retornoToast {
  type?: 'success' | 'error' | 'info';
  title: string;
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

export function returnAxiosError(error: unknown): retornoToast {
  if (isAxiosError(error)) {
    return { type: 'error', title: error.response?.data.message };
  } else {
    return { type: 'error', title: 'Erro interno' };
  }
}
