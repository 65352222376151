import { useEffect, useState } from 'react';

import api from './services/api';
import { shade } from 'polished';

import IEmpresaConfigs from './context/IEmpresaConfigs';
import ICoresData from './context/ICoresData';
import { createDecipheriv } from 'crypto';

export const useTheme = () => {
  const [cores, setCores] = useState<ICoresData>({
    primaria: '#0F0E0E',
    primariaShade: shade(0.2, '#0F0E0E'),
    secundaria: '#C4CC45',
    secundariaShade: shade(0.4, '#C4CC45'),
    texto: '#537B7D',
    textoShade: shade(0.2, '#537B7D'),
    textoClaro: '#BFBFBF',
    textoClaroShade: shade(0.2, '#BFBFBF'),
  });

  const definirCores = async () => {
    let empresaUrl = sessionStorage.getItem(`${'@ClinicaSizex'}:${'empresa'}`);

    if (empresaUrl) {
      if (!process.env.REACT_APP_CRYPTO_ALGORITMO) {
        throw new Error('Algoritmo de criptografia está vazio.');
      }
      if (!process.env.REACT_APP_CRYPTO_CHAVE) {
        throw new Error('Chave de criptografia está vazia.');
      }

      const algoritmo: string = process.env.REACT_APP_CRYPTO_ALGORITMO;
      const chave: string = process.env.REACT_APP_CRYPTO_CHAVE;

      const textParts = empresaUrl.split(':');
      const iv = Buffer.from(textParts.shift()!, 'hex');
      const encryptedText = Buffer.from(textParts.join(':'), 'hex');
      const decipher = createDecipheriv(algoritmo, chave, iv);

      const decrypted = Buffer.concat([
        decipher.update(encryptedText),
        decipher.final(),
      ]);

      empresaUrl = decrypted.toString('utf8');
    }

    let primaria = '';
    let secundaria = '';
    let texto = '';
    let textoClaro = '';

    if (empresaUrl) {
      const response = await api.get<IEmpresaConfigs[]>(
        '/empresas/configs/' + empresaUrl,
      );

      const configs = response.data;

      if (configs) {
        if (configs.length > 0) {
          let configTemp = configs.filter(
            itemFiltro => itemFiltro.parametro === 'TEMA - COR PRIMÁRIA',
          )[0];
          if (configTemp) {
            if (configTemp.valor) {
              if (configTemp.valor !== '') {
                primaria = configTemp.valor;
              }
            }
          }

          configTemp = configs.filter(
            itemFiltro => itemFiltro.parametro === 'TEMA - COR SECUNDÁRIA',
          )[0];
          if (configTemp) {
            if (configTemp.valor) {
              if (configTemp.valor !== '') {
                secundaria = configTemp.valor;
              }
            }
          }

          configTemp = configs.filter(
            itemFiltro => itemFiltro.parametro === 'TEMA - COR TEXTO',
          )[0];
          if (configTemp) {
            if (configTemp.valor) {
              if (configTemp.valor !== '') {
                texto = configTemp.valor;
              }
            }
          }

          configTemp = configs.filter(
            itemFiltro => itemFiltro.parametro === 'TEMA - COR TEXTO CLARO',
          )[0];
          if (configTemp) {
            if (configTemp.valor) {
              if (configTemp.valor !== '') {
                textoClaro = configTemp.valor;
              }
            }
          }

          setCores({
            primaria: primaria,
            primariaShade: shade(0.2, primaria),
            secundaria: secundaria,
            secundariaShade: shade(0.4, secundaria),
            texto: texto,
            textoShade: shade(0.2, texto),
            textoClaro: textoClaro,
            textoClaroShade: shade(0.2, textoClaro),
          });
        }
      }
    }
  };

  useEffect(() => {
    definirCores();
  }, []);

  return cores;
};
