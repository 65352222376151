import React, { useEffect, useState, useRef } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import {
  ColorButton,
  ColorIcon,
  ButtonContainer,
  ColorPickerDropdown,
} from './styles';

interface ColorPickerProps {
  onChange: (valor: string) => void;
  cor: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onChange, cor }) => {
  const [color, setColor] = useState('');
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    setColor(cor);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cor]);

  const handleColorChange = (selectedColor: ColorResult) => {
    setColor(selectedColor.hex);
    onChange(selectedColor.hex);
  };

  const togglePicker = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsPickerOpen(prev => !prev);
  };

  return (
    <ButtonContainer>
      <ColorButton ref={buttonRef} color={color} onClick={togglePicker}>
        <ColorIcon />
        <p>{color === '' ? 'Cor' : color}</p>
      </ColorButton>
      {isPickerOpen && (
        <ColorPickerDropdown ref={dropdownRef}>
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        </ColorPickerDropdown>
      )}
    </ButtonContainer>
  );
};

export default ColorPicker;
