import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Opcao {
  id: string;
  nome: string;
}

interface ComboboxComFiltroProps {
  opcoes: Opcao[];
  value: string | null;
  onChange: (id: string | null) => void;
  error?: boolean;
  helperText?: string;
}

const ComboboxComFiltro: React.FC<ComboboxComFiltroProps> = ({
  opcoes,
  value,
  onChange,
  error = false,
  helperText = '',
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      style={{ flex: 1, width: '100%' }}
      options={opcoes}
      getOptionLabel={option => option.nome}
      value={opcoes.find(option => option.id === value) || null}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.id : null);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText="Sem Opções"
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          margin="normal"
          label="Convênio"
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default ComboboxComFiltro;
