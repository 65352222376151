import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
// import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { useConfigs } from '../../context/ConfigsContext';

import getValidationErrors from '../../utils/getValidationErros';

import logoImg from '../../assets/Sizex Clin Logo.png';

import Input from '../../components/input';
import Button from '../../components/button';

import { Container, Content, AnimationContainer, Background } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn, user } = useAuth();
  const { addToast } = useToast();
  const { cores } = useConfigs();

  const history = useHistory();

  const [carregarTela, setcarregarTela] = useState<boolean>(false);

  useEffect(() => {
    // TRATAMENTO PARA CASO DE EMPRESA QUE NÃO UTILIZA LOGIN
    if (user) {
      // primeiro pelo gravado no local storafe para caso de F5 na página
      if (user.empresa.utiliza_login.toUpperCase() === 'N') {
        history.push('/home');
      }
    } else {
      // vai tentar sign só com a empresa (usuário inexistente) - se empresa utiliza login vai dar erro que é tratado
      signIn({
        email: '123@sizex123.com.br',
        password: '123',
      })
        .then(resposta => {
          // Caso de não utilizar login
          setcarregarTela(true);
        })
        .catch(() => {
          // Caso que utiliza login
          setcarregarTela(true);
        });
    }
  }, [history, signIn, user]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      // console.log(data);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        // Config abortEarly para não parar no primeiro erro de validação e retornar todos
        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast],
  ); //Toda variável externa usada no useCallback tem q entrar no arrau de dependências

  return (
    <Container>
      {carregarTela && (
        <>
          <Content>
            <AnimationContainer cores={cores}>
              <img src={logoImg} alt="Logo Sizex" height={280} width={280} />

              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Faça seu logon</h1>

                <Input
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  icon={FiMail}
                  autoFocus
                />
                <Input
                  name="password"
                  type="password"
                  placeholder="Senha"
                  icon={FiLock}
                />

                <Button type="submit">Entrar</Button>

                {/* <Link to="/forgot-password">Esqueci minha senha</Link> */}
              </Form>

              {/* <Link to="/signup">
                      <FiLogIn />
                      Criar conta
                  </Link> */}
            </AnimationContainer>
          </Content>

          <Background />
        </>
      )}
    </Container>
  );
};

export default SignIn;
