import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import logoPadrao from '../../assets/sem-logo.png';
import { FiCamera, FiTrash2 } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { returnAxiosError } from '../../utils/returnAxiosError';

import {
  Container,
  ContainerInputs,
  Pesquisa,
  InputConvenio,
  CardConvenio,
  GroupBox,
  GroupBoxLegenda,
} from './styles';

interface Convenio {
  id: string;
  nome: string;
  logoconvenio: string;
  logo_url: string;
}

const Convenio: React.FC = () => {
  const { addToast } = useToast();

  const [convenios, setConvenios] = useState<Convenio[]>([]);
  const [nomeConvenio, setNomeConvenio] = useState<string>('');
  const [convenioSelecionado, setConvenioSelecionado] = useState<
    Convenio | undefined
  >(undefined);

  const updateListaConvenio = useCallback(() => {
    api.get<Convenio[]>('/convenio/lista').then(response => {
      setConvenios(response.data);
    });
  }, [setConvenios]);

  useEffect(() => {
    updateListaConvenio();
  }, [updateListaConvenio]);

  const handleLogoChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, id: string) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('imagem', e.target.files[0]);

        api.patch('/convenio/logo/' + id, data).then(() => {
          updateListaConvenio();

          addToast({
            type: 'success',
            title: 'Logo atualizado!',
          });
        });
      }
    },
    [addToast, updateListaConvenio],
  );

  const handleSalvarConvenio = useCallback(async () => {
    try {
      if (convenioSelecionado) {
        await api.put('/convenio/' + convenioSelecionado.id, {
          nome: nomeConvenio,
        });
      } else {
        await api.post('/convenio', { nome: nomeConvenio });
      }
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    addToast({
      type: 'success',
      title: 'Convênio salvo!',
    });

    setNomeConvenio('');
    setConvenioSelecionado(undefined);
    updateListaConvenio();
  }, [nomeConvenio, addToast, updateListaConvenio, convenioSelecionado]);

  const handleExcluirConvenio = useCallback(
    async (id: string) => {
      await api.delete(`/convenio/${id}`);

      addToast({
        type: 'success',
        title: 'Convênio excluído!',
      });

      setNomeConvenio('');
      setConvenioSelecionado(undefined);
      updateListaConvenio();
    },
    [updateListaConvenio, addToast],
  );

  return (
    <Container>
      <GroupBox>
        <GroupBoxLegenda>Convenio</GroupBoxLegenda>

        <ContainerInputs>
          <InputConvenio
            variant="outlined"
            margin="normal"
            id="nomeConvenio"
            label="Nome Convênio"
            name="nomeConvenio"
            type="text"
            value={nomeConvenio}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNomeConvenio(e.target.value)
            }
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handleSalvarConvenio();
              }
            }}
          />
          {/* <input
            variant="outlined"
            margin="normal"
            id="ufCliente"
            label="UF"
            name="ufCliente"
            type="text"
            placeholder="Nome do Convênio"
            value={nomeConvenio}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNomeConvenio(e.target.value)
            }
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handleSalvarConvenio();
              }
            }}
          /> */}

          <button onClick={handleSalvarConvenio}>Salvar</button>
        </ContainerInputs>

        <Pesquisa>
          {convenios.map(convenio => (
            <CardConvenio
              key={convenio.id}
              onClick={() => {
                setNomeConvenio(convenio.nome);
                setConvenioSelecionado(convenio);
              }}
            >
              <div>
                {convenio.nome}
                <img
                  src={!convenio.logo_url ? logoPadrao : convenio.logo_url}
                  alt="logo convênio"
                />
              </div>

              <div>
                <label htmlFor={`avatar-${convenio.id}`}>
                  <FiCamera />
                  <input
                    type="file"
                    id={`avatar-${convenio.id}`}
                    onChange={e => {
                      handleLogoChange(e, convenio.id);
                    }}
                  />
                </label>
                <FiTrash2
                  onClick={() => {
                    handleExcluirConvenio(convenio.id);
                  }}
                />
              </div>
            </CardConvenio>
          ))}
        </Pesquisa>
      </GroupBox>
    </Container>
  );
};

export default Convenio;
