import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
// import SignUp from '../pages/SignUp';
// import ForgotPassword from '../pages/ForgotPassword';
// import ResetPassword from '../pages/ResetPassword';

// import Home from '../pages/Home';
import Agenda from '../pages/Agenda';
import Carrinho from '../pages/Carrinho';
import Profile from '../pages/Profile';
import PesquisaClientes from '../pages/PesquisaClientes';
import Cliente from '../pages/Cliente';
import Vendas from '../pages/Vendas';

import NaoEncontrada from '../pages/NaoEncontrada';

const Routes: React.FC = () => (
  <Switch>
    {/* <Route path="/signup" component={SignUp} /> */}
    {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
    {/* <Route path="/reset-password" component={ResetPassword} /> */}

    <Route path="/home" component={Agenda} isPrivate />
    <Route path="/carrinho" component={Carrinho} isPrivate />
    <Route path="/pesquisa-clientes" component={PesquisaClientes} isPrivate />
    <Route path="/vendas" component={Vendas} isPrivate />
    <Route path="/cliente" component={Cliente} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/nao-encontrada" exact component={NaoEncontrada} />

    <Route path="/:empresa" exact component={SignIn} />

    <Route component={NaoEncontrada} />
  </Switch>
);

export default Routes;
