import React, { useState, useRef, useCallback, useEffect } from 'react';
import ComboboxComFiltro from '../../components/autocomplete';

import {
  Container,
  GroupBoxPaciente,
  GroupBoxPesquisa,
  GroupBoxContatos,
  GroupBoxLegenda,
  InputCpf,
  InputNome,
  InputNomeMae,
  InputNomePai,
  InputBairro,
  InputCep,
  InputCidade,
  InputComplemento,
  InputEndereco,
  InputNumero,
  InputUf,
  InputData,
  InputTipoContato,
  InputValorContato,
  InputObsContato,
  AddContatoButton,
  ListaContatos,
  CardContato,
  InfoContato,
  TipoContato,
  ExcluirContato,
  BotoesContainer,
  BotaoLimpar,
  BotaoSalvar,
  BotaoExcluir,
  TabButton,
  ContainerButton,
  InputNomeMaePesquisa,
  InputNomePaiPesquisa,
  InputNomePesquisa,
  PesquisaButton,
  ContainerPesquisa,
  CardPacientePesquisa,
} from './styles';

import apiViaCep from '../../services/apiViaCep';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import {
  FiSave,
  FiTrash2,
  FiPlus,
  FiRefreshCw,
  FiFilter,
  FiSearch,
  FiUserPlus,
} from 'react-icons/fi';

interface Convenio {
  nome: string;
  logoconvenio: string;
}

interface Paciente {
  id?: string;
  nome: string;
  cpf: string;
  data_nascimento: string;
  convenio_id: string;
  cidade: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  estado: string;
  nome_pai: string;
  nome_mae: string;
  contatos: PacienteContatosGravar[];
  convenio?: Convenio;
}

interface PacienteContatosGravar {
  tipo: string;
  valor: string;
  obs: string;
}

interface RetornoConvenios {
  id: string;
  nome: string;
}

interface RetornoViaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
}

const Paciente: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { gravarIndexedDB } = useIndexedDB();

  const [isMobile, setIsMobile] = useState(false);
  const [tabAtiva, setTabAtiva] = useState('Cadastro');
  const [convenios, setConvenios] = useState<RetornoConvenios[]>([]);
  const [contatos, setContatos] = useState<PacienteContatosGravar[]>([]);
  const [pacientesPesquisa, setPacientesPesquisa] = useState<Paciente[]>();
  const [pacientesFiltradosPesquisa, setPacientesFiltradosPesquisa] =
    useState<Paciente[]>();
  const [pacienteSelecionado, setPacienteSelecionado] = useState<Paciente>();

  //Campos obrigatórios
  const [cpf, setCpf] = useState<string>('');
  const [cpfErro, setCpfErro] = useState<string>('');
  const [dataNascimento, setDataNascimento] = useState<string>('');
  const [dataNascimentoErro, setDataNascimentoErro] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [nomeErro, setNomeErro] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [cidadeErro, setCidadeErro] = useState<string>('');
  const [tipoContato, setTipoContato] = useState<string>('');
  const [tipoContatoErro, setTipoContatoErro] = useState<string>('');
  const [valorContato, setValorContato] = useState<string>('');
  const [valorContatoErro, setValorContatoErro] = useState<string>('');
  const [convenio, setConvenio] = useState<string | null>(null);
  const [convenioErro, setConvenioErro] = useState<string>('');

  //Campos opcionais
  const [nomePai, setNomePai] = useState<string>('');
  const [nomeMae, setNomeMae] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [endereco, setEndereco] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [obsContato, setObsContato] = useState<string>('');

  //Campos pesquisa
  const [nomePesquisa, setNomePesquisa] = useState<string>('');
  const [nomeMaePesquisa, setNomeMaePesquisa] = useState<string>('');
  const [nomePaiPesquisa, setNomePaiPesquisa] = useState<string>('');
  const [convenioPesquisa, setConvenioPesquisa] = useState<string | null>(null);

  const inputCpf = useRef<HTMLInputElement>(null);
  const inputDataNascimento = useRef<HTMLInputElement>(null);
  const inputNome = useRef<HTMLInputElement>(null);
  const inputNomePai = useRef<HTMLInputElement>(null);
  const inputNomeMae = useRef<HTMLInputElement>(null);
  const inputCep = useRef<HTMLInputElement>(null);
  const inputEndereco = useRef<HTMLInputElement>(null);
  const inputNumero = useRef<HTMLInputElement>(null);
  const inputBairro = useRef<HTMLInputElement>(null);
  const inputComplemento = useRef<HTMLInputElement>(null);
  const inputCidade = useRef<HTMLInputElement>(null);
  const inputUf = useRef<HTMLInputElement>(null);
  const inputTipoContato = useRef<HTMLInputElement>(null);
  const inputNomePesquisa = useRef<HTMLInputElement>(null);
  const inputNomeMaePesquisa = useRef<HTMLInputElement>(null);
  const inputNomePaiPesquisa = useRef<HTMLInputElement>(null);

  const validacoesPaciente = useCallback(() => {
    setCpfErro('');
    setNomeErro('');
    setDataNascimentoErro('');
    setCidadeErro('');
    setConvenioErro('');

    let erroValidacao = false;
    if (cpf === '') {
      setCpfErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (nome === '') {
      setNomeErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (dataNascimento === '') {
      setDataNascimentoErro('Informação obrigatória!');
      erroValidacao = true;
    }
    console.log(convenio);
    if (!convenio) {
      setConvenioErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (cidade === '') {
      setCidadeErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [addToast, cidade, convenio, cpf, dataNascimento, nome]);

  const handleAdicionarContato = useCallback(() => {
    if (validacoesPaciente() === false) {
      return;
    }

    setTipoContatoErro('');
    setValorContatoErro('');

    let erroValidacao = false;
    if (tipoContato === '') {
      erroValidacao = true;
      setTipoContatoErro('Informação obrigatória');
    }
    if (valorContato === '') {
      erroValidacao = true;
      setValorContatoErro('Informação obrigatória');
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação dos contatos',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return;
    }

    const contatoAdicionar: PacienteContatosGravar = {
      tipo: tipoContato.toUpperCase(),
      valor: valorContato,
      obs: obsContato.toUpperCase(),
    };

    setContatos(contatos => [...contatos, contatoAdicionar]);

    setTipoContato('');
    setValorContato('');
    setObsContato('');

    inputTipoContato.current?.focus();
  }, [
    validacoesPaciente,
    tipoContato,
    setTipoContato,
    setTipoContatoErro,
    valorContato,
    setValorContato,
    setValorContatoErro,
    addToast,
    obsContato,
    setObsContato,
    inputTipoContato,
  ]);

  const handleExcluirContato = useCallback((valor: string) => {
    setContatos(contatos =>
      contatos.filter(contato => contato.valor !== valor),
    );
  }, []);

  const handleLimparCampos = useCallback(() => {
    setCpf('');
    setNome('');
    setDataNascimento('');
    setNomePai('');
    setNomeMae('');
    setConvenio('');
    setCep('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setComplemento('');
    setCidade('');
    setUf('');

    setCpfErro('');
    setNomeErro('');
    setDataNascimentoErro('');
    setConvenioErro('');
    setCidadeErro('');

    setTipoContato('');
    setValorContato('');
    setObsContato('');

    setContatos([]);
  }, []);

  const atualizaDados = async () => {
    try {
      const [conveniosResponse, pacientesResponse] = await Promise.all([
        api.get<RetornoConvenios[]>('/convenio/lista'),
        api.get<Paciente[]>('/pacientes/'),
      ]);

      setConvenios(conveniosResponse.data);
      setPacientesPesquisa(pacientesResponse.data);
      setPacientesFiltradosPesquisa(pacientesResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const handleSelecionarPaciente = useCallback((paciente: Paciente) => {
    if (!paciente) {
      return;
    }

    setCpf(paciente.cpf);
    setNome(paciente.nome);

    const data = new Date(paciente.data_nascimento);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Adiciona 1 ao mês, pois começa em 0
    const ano = data.getFullYear();

    const dataFormatada = `${ano}-${mes}-${dia}`;

    setDataNascimento(dataFormatada);
    setNomePai(paciente.nome_pai);
    setNomeMae(paciente.nome_mae);
    setConvenio(paciente.convenio_id);
    setCep(paciente.cep);
    setEndereco(paciente.endereco);
    setNumero(paciente.numero);
    setBairro(paciente.bairro);
    setComplemento(paciente.complemento);
    setCidade(paciente.cidade);
    setUf(paciente.estado);

    setContatos(paciente.contatos);

    setTabAtiva('Cadastro');
    setPacienteSelecionado(paciente);
  }, []);

  const handlePesquisa = useCallback(() => {
    let listaFiltrar = pacientesPesquisa;

    if (nomePesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome.toUpperCase().includes(nomePesquisa.toUpperCase()),
      );
    }

    if (convenioPesquisa) {
      listaFiltrar = listaFiltrar?.filter(
        pacientes => pacientes.convenio_id === convenioPesquisa,
      );
    }

    if (nomePaiPesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome_pai
          .toUpperCase()
          .includes(nomePaiPesquisa.toUpperCase()),
      );
    }

    if (nomeMaePesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome_mae
          .toUpperCase()
          .includes(nomeMaePesquisa.toUpperCase()),
      );
    }

    setPacientesFiltradosPesquisa(listaFiltrar);
  }, [
    nomePesquisa,
    convenioPesquisa,
    nomePaiPesquisa,
    nomeMaePesquisa,
    pacientesPesquisa,
  ]);

  const handleLimparPesquisa = useCallback(() => {
    setNomePesquisa('');
    setConvenioPesquisa(null);
    setNomePaiPesquisa('');
    setNomeMaePesquisa('');

    setPacientesFiltradosPesquisa(pacientesPesquisa);
  }, [pacientesPesquisa]);

  const handleExcluirPaciente = useCallback(async () => {
    if (!pacienteSelecionado) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir',
        description: 'Nenhum paciente selecionado para a exclusão.',
      });
      return;
    }

    await api.delete(`/pacientes/${pacienteSelecionado.id}`);

    addToast({
      type: 'success',
      title: 'Parabéns',
      description: 'Paciente excluído com sucesso!',
    });

    setPacienteSelecionado(undefined);
    handleLimparCampos();
    atualizaDados();
  }, [pacienteSelecionado, addToast, handleLimparCampos]);

  const aoAlterarNomePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarNomeMaePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomeMaePesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarNomePaiPesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePaiPesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarConvenioPesquisa = useCallback((id: string | null) => {
    setConvenioPesquisa(id);
  }, []);

  const aoAlterarCnpjcpf = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCpf(event.target.value);
      setCpfErro('');
    },
    [],
  );

  const aoAlterarDataInicial = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDataNascimento(event.target.value);
      setDataNascimentoErro('');
    },
    [],
  );

  const aoAlterarNome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNome(event.target.value);
      setNomeErro('');
    },
    [],
  );

  const aoAlterarNomePai = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePai(event.target.value);
    },
    [],
  );

  const aoAlterarNomeMae = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomeMae(event.target.value);
    },
    [],
  );

  // const aoAlterarConvenio = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setConvenio(event.target.value);
  //     setConvenioErro('');
  //   },
  //   [],
  // );

  const aoAlterarConvenio = (novoConvenio: string | null) => {
    setConvenio(novoConvenio);
    setConvenioErro('');
  };

  const aoAlterarCep = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCep(event.target.value);
    },
    [],
  );

  const aoSairCep = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      let cepTemp: string = event.target.value;

      cepTemp = cepTemp.replace(/[^0-9]/g, '');

      if (endereco === '') {
        apiViaCep.get<RetornoViaCep>(`/${cepTemp}/json/`).then(response => {
          if (response.data) {
            const retornoCep: RetornoViaCep = response.data;
            if (retornoCep) {
              if (retornoCep.logradouro) {
                setEndereco(retornoCep.logradouro);
              }
              if (retornoCep.localidade) {
                setCidade(retornoCep.localidade);
              }
              if (retornoCep.bairro) {
                setBairro(retornoCep.bairro);
              }
              if (retornoCep.complemento) {
                setComplemento(retornoCep.complemento);
              }
              if (retornoCep.uf) {
                setUf(retornoCep.uf);
              }
              inputNumero.current?.focus();
            }
          }
        });
      }

      if (cepTemp.length === 8) {
        const cepFormat = `${cepTemp.substr(0, 5)}-${cepTemp.substr(5, 3)}`;
        setCep(cepFormat);
      }
    },
    [setCep, endereco],
  );

  const aoAlterarEndereco = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEndereco(event.target.value);
    },
    [],
  );

  const aoAlterarNumero = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNumero(event.target.value);
    },
    [],
  );

  const aoAlterarBairro = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBairro(event.target.value);
    },
    [],
  );

  const aoAlterarComplemento = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComplemento(event.target.value);
    },
    [],
  );

  const aoAlterarCidade = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCidade(event.target.value);
      setCidadeErro('');
    },
    [],
  );

  const aoAlterarUf = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUf(event.target.value);
    },
    [],
  );

  const aoAlterarTipo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTipoContato(event.target.value);
    },
    [],
  );

  const aoAlterarValor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValorContato(event.target.value);
    },
    [],
  );

  const aoAlterarObs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setObsContato(event.target.value);
    },
    [],
  );

  const toggleTab = useCallback(() => {
    if (tabAtiva === 'Cadastro') {
      setTabAtiva('Pesquisa');
    } else {
      setTabAtiva('Cadastro');
    }
  }, [tabAtiva, setTabAtiva]);

  const aoSalvar = useCallback(async () => {
    if (validacoesPaciente() === false) {
      return;
    }

    if (contatos.length === 0) {
      addToast({
        type: 'error',
        title: 'Validação Contatos',
        description:
          'Pelo menos 1 contato deve ser informado para realizar o cadastro.',
      });
      return;
    }

    const [dia, mes, ano] = dataNascimento.split('/');

    const dataFormatada = `${ano}-${mes}-${dia}`;

    const pacienteGravar: Paciente = {
      cpf,
      nome: nome.toUpperCase(),
      data_nascimento: dataFormatada,
      nome_pai: nomePai.toUpperCase(),
      nome_mae: nomeMae.toUpperCase(),
      convenio_id: convenio ? convenio : '',
      cep,
      endereco: endereco.toUpperCase(),
      numero,
      bairro: bairro.toUpperCase(),
      complemento: complemento.toUpperCase(),
      cidade: cidade.toUpperCase(),
      estado: uf.toUpperCase(),
      contatos,
    };

    const retornoGravacao = await api.post('pacientes', pacienteGravar);
    if (retornoGravacao.status !== 200) {
      addToast({
        type: 'error',
        title: 'Falha ao Incluir Paciente! Tente novamente em alguns minutos!',
      });
    } else {
      addToast({
        type: 'success',
        title: 'Paciente salvo com sucesso!!!',
      });

      // VOU SALVAR O NOVO CLIENTE NO STORAGE PARA USAR DEPOIS ONDE PRECISAR
      const novoPaciente: Paciente = retornoGravacao.data;
      gravarIndexedDB(
        `${user.empresa.url_frontend}:paciente-gravado`,
        novoPaciente,
      );
    }

    handleLimparCampos();
    atualizaDados();
  }, [
    validacoesPaciente,
    addToast,
    bairro,
    cep,
    cidade,
    complemento,
    contatos,
    convenio,
    cpf,
    dataNascimento,
    endereco,
    gravarIndexedDB,
    nome,
    nomeMae,
    nomePai,
    numero,
    uf,
    user.empresa.url_frontend,
    handleLimparCampos,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    atualizaDados();
  }, []);

  return (
    <Container>
      <TabButton onClick={toggleTab}>
        {tabAtiva === 'Cadastro' ? <FiFilter /> : <FiUserPlus />}
      </TabButton>

      {tabAtiva === 'Cadastro' ? (
        <>
          <GroupBoxPaciente>
            <GroupBoxLegenda>Paciente</GroupBoxLegenda>

            <span>
              <InputCpf
                variant="outlined"
                margin="normal"
                id="cnpjcpf"
                label="CPF"
                name="cnpjcpf"
                inputRef={inputCpf}
                value={cpf}
                onChange={aoAlterarCnpjcpf}
                error={cpfErro !== ''}
                helperText={cpfErro}
              />

              <InputNome
                variant="outlined"
                margin="normal"
                id="nome"
                label="Nome"
                name="nome"
                inputRef={inputNome}
                value={nome}
                onChange={aoAlterarNome}
                error={nomeErro !== ''}
                helperText={nomeErro}
              />

              <InputData
                variant="outlined"
                margin="normal"
                id="dataNascimento"
                label="Data Nascimento"
                name="dataNascimento"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={inputDataNascimento}
                value={dataNascimento}
                onChange={aoAlterarDataInicial}
                error={dataNascimentoErro !== ''}
                helperText={dataNascimentoErro}
              />
            </span>

            <span>
              <InputNomePai
                variant="outlined"
                margin="normal"
                id="nomePai"
                label="Nome Pai"
                name="nomePai"
                inputRef={inputNomePai}
                value={nomePai}
                onChange={aoAlterarNomePai}
              />

              <InputNomeMae
                variant="outlined"
                margin="normal"
                id="nomeMae"
                label="Nome Mãe"
                name="nomeMae"
                inputRef={inputNomeMae}
                value={nomeMae}
                onChange={aoAlterarNomeMae}
              />

              {/* <InputConvenio
                variant="outlined"
                margin="normal"
                select
                id="convenio"
                label="Convênio"
                name="convenio"
                inputRef={inputConvenio}
                value={convenio}
                onChange={aoAlterarConvenio}
                error={convenioErro !== ''}
                helperText={convenioErro}
              >
                {convenios.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nome}
                  </MenuItem>
                ))}
              </InputConvenio> */}

              <ComboboxComFiltro
                opcoes={convenios}
                value={convenio}
                onChange={aoAlterarConvenio}
                error={convenioErro !== ''}
                helperText={convenioErro}
              />
            </span>

            <span>
              <InputCep
                variant="outlined"
                margin="normal"
                id="cep"
                label="CEP"
                name="cep"
                inputRef={inputCep}
                value={cep}
                onChange={aoAlterarCep}
                onBlur={aoSairCep}
              />

              <InputEndereco
                variant="outlined"
                margin="normal"
                id="enderecoCliente"
                label="Endereço"
                name="enderecoCliente"
                inputRef={inputEndereco}
                value={endereco}
                onChange={aoAlterarEndereco}
              />

              <InputNumero
                variant="outlined"
                margin="normal"
                id="numeroCliente"
                label="Número"
                name="numeroCliente"
                inputRef={inputNumero}
                value={numero}
                onChange={aoAlterarNumero}
              />
            </span>

            <span>
              <InputBairro
                variant="outlined"
                margin="normal"
                id="bairroCliente"
                label="Bairro"
                name="bairroCliente"
                inputRef={inputBairro}
                value={bairro}
                onChange={aoAlterarBairro}
              />

              <InputComplemento
                variant="outlined"
                margin="normal"
                id="complementoCliente"
                label="Complemento"
                name="complementoCliente"
                inputRef={inputComplemento}
                value={complemento}
                onChange={aoAlterarComplemento}
              />
            </span>

            <span>
              <InputCidade
                variant="outlined"
                margin="normal"
                id="cidadeCliente"
                label="Cidade"
                name="cidadeCliente"
                inputRef={inputCidade}
                value={cidade}
                onChange={aoAlterarCidade}
                error={cidadeErro !== ''}
                helperText={cidadeErro}
              />

              <InputUf
                variant="outlined"
                margin="normal"
                id="ufCliente"
                label="UF"
                name="ufCliente"
                inputRef={inputUf}
                value={uf}
                onChange={aoAlterarUf}
              />
            </span>

            <GroupBoxContatos>
              <GroupBoxLegenda>Contatos</GroupBoxLegenda>

              <span>
                <InputTipoContato
                  variant="outlined"
                  margin="normal"
                  id="tipoContato"
                  label="Tipo Contato"
                  name="tipoContato"
                  value={tipoContato}
                  onChange={aoAlterarTipo}
                  error={tipoContatoErro !== ''}
                  inputRef={inputTipoContato}
                  helperText={tipoContatoErro}
                />

                <InputValorContato
                  variant="outlined"
                  margin="normal"
                  id="valorContato"
                  label="Dados Contato"
                  name="valorContato"
                  value={valorContato}
                  onChange={aoAlterarValor}
                  error={valorContatoErro !== ''}
                  helperText={valorContatoErro}
                />

                <ContainerButton>
                  <InputObsContato
                    variant="outlined"
                    margin="normal"
                    id="obsContato"
                    label="Observações"
                    name="obsContato"
                    value={obsContato}
                    onChange={aoAlterarObs}
                  />

                  <AddContatoButton onClick={handleAdicionarContato}>
                    <FiPlus />
                  </AddContatoButton>
                </ContainerButton>
              </span>

              <ListaContatos>
                {contatos.map(contato => {
                  return isMobile ? (
                    <CardContato key={contato.valor}>
                      <FiTrash2
                        onClick={() => {
                          handleExcluirContato(contato.valor);
                        }}
                      />
                      <div>{contato.tipo}</div>
                      <div>{contato.valor}</div>
                      <div>{contato.obs}</div>
                    </CardContato>
                  ) : (
                    <CardContato key={contato.valor}>
                      <TipoContato>{contato.tipo}</TipoContato>

                      <InfoContato
                        variant="outlined"
                        margin="normal"
                        id="valorContato"
                        label="Dados Contato"
                        name="valorContato"
                        value={contato.valor}
                        inputProps={{ readOnly: true }}
                      />
                      {/* <InfoContato>
                        <p>Valor:</p> {contato.valor}
                      </InfoContato> */}

                      <ContainerButton>
                        <InfoContato
                          variant="outlined"
                          margin="normal"
                          id="obsContato"
                          label="Observações"
                          name="obsContato"
                          value={contato.obs ? contato.obs : ''}
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {/* <InfoContato>
                        <p>Obs:</p> {contato.obs}
                      </InfoContato> */}

                        <ExcluirContato
                          onClick={() => {
                            handleExcluirContato(contato.valor);
                          }}
                        >
                          <FiTrash2 />
                        </ExcluirContato>
                      </ContainerButton>
                    </CardContato>
                  );
                })}
              </ListaContatos>
            </GroupBoxContatos>
          </GroupBoxPaciente>

          <BotoesContainer>
            <BotaoSalvar onClick={aoSalvar}>
              <FiSave />
              Salvar
            </BotaoSalvar>

            <BotaoLimpar onClick={handleLimparCampos}>
              <FiRefreshCw />
              Limpar
            </BotaoLimpar>

            <BotaoExcluir onClick={handleExcluirPaciente}>
              <FiTrash2 />
              Excluir
            </BotaoExcluir>
          </BotoesContainer>
        </>
      ) : (
        <GroupBoxPesquisa>
          <GroupBoxLegenda>Pesquisa</GroupBoxLegenda>

          <span>
            <InputNomePesquisa
              variant="outlined"
              margin="normal"
              id="nomePesq"
              label="Nome"
              name="nomePesq"
              inputRef={inputNomePesquisa}
              value={nomePesquisa}
              onChange={aoAlterarNomePesquisa}
            />

            {/* <InputConvenioPesquisa
              variant="outlined"
              margin="normal"
              select
              id="convenioPesq"
              label="Convênio"
              name="convenioPesq"
              inputRef={inputConvenioPesquisa}
              value={convenioPesquisa}
              onChange={aoAlterarConvenioPesquisa}
            >
              {convenios.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              ))}
            </InputConvenioPesquisa> */}

            <ComboboxComFiltro
              opcoes={convenios}
              value={convenioPesquisa}
              onChange={aoAlterarConvenioPesquisa}
            />
          </span>

          <span>
            <InputNomePaiPesquisa
              variant="outlined"
              margin="normal"
              id="nomePaiPesq"
              label="Nome Pai"
              name="nomePaiPesq"
              inputRef={inputNomePaiPesquisa}
              value={nomePaiPesquisa}
              onChange={aoAlterarNomePaiPesquisa}
            />

            <ContainerButton>
              <InputNomeMaePesquisa
                variant="outlined"
                margin="normal"
                id="nomeMaePesq"
                label="Nome Mãe"
                name="nomeMaePesq"
                inputRef={inputNomeMaePesquisa}
                value={nomeMaePesquisa}
                onChange={aoAlterarNomeMaePesquisa}
              />

              <PesquisaButton onClick={handlePesquisa}>
                <FiSearch />
              </PesquisaButton>

              <PesquisaButton onClick={handleLimparPesquisa}>
                <FiRefreshCw />
              </PesquisaButton>
            </ContainerButton>
          </span>

          <ContainerPesquisa>
            {pacientesFiltradosPesquisa?.map(paciente => (
              <CardPacientePesquisa
                key={paciente.id}
                onClick={() => {
                  handleSelecionarPaciente(paciente);
                }}
              >
                <span>
                  <p>{paciente.nome}</p>
                  <p> - </p>
                  <p>{paciente.convenio?.nome}</p>
                </span>
                <p>{paciente.nome_mae}</p>
                <p>
                  {
                    paciente.contatos.find(contato =>
                      /FONE|CEL/i.test(contato.tipo),
                    )?.valor
                  }
                </p>
              </CardPacientePesquisa>
            ))}
          </ContainerPesquisa>
        </GroupBoxPesquisa>
      )}
    </Container>
  );
};

export default Paciente;
