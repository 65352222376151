import styled from 'styled-components';
import { FiDroplet } from 'react-icons/fi';

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;

  background-color: white;
  width: 120px;
  @media (max-width: 610px) {
    width: auto;
  }
`;

export const ColorButton = styled.button<{ color: string }>`
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 12px;
  background-color: white;
  border: 2px solid ${props => props.color || props.theme.secundaria};
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.color || props.theme.secundaria};
  outline: none;
  font-weight: bold;

  @media (max-width: 610px) {
    p {
      display: none;
    }
  }
`;

export const ColorIcon = styled(FiDroplet)`
  width: 24px;
  height: 24px;
`;

export const ColorPickerDropdown = styled.div`
  position: absolute;
  top: calc(100% + 8px); /* Posiciona o dropdown logo abaixo do botão */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 5px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${props => props.theme.secundaria};
  }
`;
