import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { useTheme } from '../src/theme';
import GlobalStyle from './styles/global';

import AppProvider from './context';

import Routes from './routes';

const App: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <Routes />
          </AppProvider>

          <GlobalStyle />
        </ThemeProvider>
      </Router>
    </>
  );
};

export default App;
